import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@mui/material';

import { NONE } from 'constants/dinerSignupConstants';

// TODO: add close popup logic https://getbentobox.atlassian.net/browse/DMD-2832
const AlertButton = ({ buttonProps, handleClick = null }) => {
  if (buttonProps.action === NONE) {
    return null;
  }
  return (
    <Button
      sx={theme => ({
        ...theme.brandStyles?.button,
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
        '&:hover': {
          background: theme?.brandStyles?.buttonHover?.hoverBackgroundColor,
          borderColor: theme?.brandStyles?.buttonHover?.hoverStrokeColor,
          color: theme?.brandStyles?.buttonHover?.hoverFontColor
        }
      })}
      disableElevation
      variant="contained"
      onClick={() => handleClick(buttonProps)}
    >
      {buttonProps?.label}
    </Button>
  );
};

AlertButton.propTypes = {
  buttonProps: PropTypes.shape({
    action: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string
  }),
  handleClick: PropTypes.func.isRequired
};

AlertButton.defaultProps = {
  buttonProps: {}
};

export default AlertButton;
