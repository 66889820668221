/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import BirthdayField from 'components/BirthdayField';
import { EmailField } from 'components/EmailField';

const AlertForm = ({
  formContext,
  handleEmailChange,
  handleBirthdayChange,
  errors
}) =>
  formContext.alert_form_fields.map(
    field =>
      field.enabled &&
      (field.input_type === 'birthday' ? (
        <BirthdayField
          key={field.id}
          error={errors?.birthday}
          onChange={handleBirthdayChange}
        />
      ) : (
        <EmailField
          key={field.id}
          handleEmailChange={handleEmailChange}
          field={field}
          error={errors?.email}
        />
      ))
  );

AlertForm.propTypes = {
  formContext: PropTypes.shape({
    alert_form_fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string
      })
    )
  }).isRequired
};

export default AlertForm;
